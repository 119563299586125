import React, { useContext } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import { GlobalStateContext } from '../../utils/GlobalContextProvider';
import logoVitaFons from '../../../static/logoVitaFons.jpg'
import bellhouse from '../../../static/bellhouse.png'
import chakras from '../../../static/chakras.jpg'
import Logo from '../Logo.svg'
import StyledPageVitaFonsII from './StyledPageVitaFonsII';

export const PageVitaFonsII = () => {
    const { pageVitaFonsII } = useStaticQuery(
        graphql`
            query {
                pageVitaFonsII:datoCmsPagePresentationVitafonsii {
                    encart1Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart2Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart3Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart4Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart5Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart6Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart7Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart8Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                  }
            }
          `)
    const { titlePage } = useContext(GlobalStateContext)
    return (
        <StyledPageVitaFonsII>
            <div className='container-page'>
                <div className='wrapper-titleh3'>
                    <h3>{titlePage} / <small>un principe nouveau pour la science</small></h3>
                </div>
                <div className='container-img-txt'>
                    <img id='logo' alt='logo' src={logoVitaFons} />
                    <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pageVitaFonsII.encart1Node.childMarkdownRemark.html}} />
                </div>
                <div className='little-separator' />
                <div className='container-txt2' dangerouslySetInnerHTML={{ __html: pageVitaFonsII.encart2Node.childMarkdownRemark.html}} />
                <div className='container-img-txt-left'>
                    <img className='img' alt='elizabeth' src={bellhouse} />
                    <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pageVitaFonsII.encart3Node.childMarkdownRemark.html}} />
                </div>
                <div className='container-txt2' dangerouslySetInnerHTML={{ __html: pageVitaFonsII.encart4Node.childMarkdownRemark.html}} />
                <div className='container-img-txt-right'>
                    <img className='img' alt='logoFontVie' src={Logo} />
                    <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pageVitaFonsII.encart5Node.childMarkdownRemark.html}} />
                </div>
                <div className='container-txt2' dangerouslySetInnerHTML={{ __html: pageVitaFonsII.encart6Node.childMarkdownRemark.html}} />
                <div className="container-img-txt two">
                    <img className='img' alt='chakras' src={chakras} />
                    <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pageVitaFonsII.encart7Node.childMarkdownRemark.html}} />
                </div>
                <div className='container-txt2' dangerouslySetInnerHTML={{ __html: pageVitaFonsII.encart8Node.childMarkdownRemark.html}} />
            </div>
        </StyledPageVitaFonsII>
    )
}

