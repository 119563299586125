import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageVitaFonsII } from '../components/PageVitaFonsII/PageVitaFonsII';

const PresentationVitaFons = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
        <SEO title="Présentation de VITA FONS II" />
        <PageVitaFonsII />
    </Layout>
  )
}

export default PresentationVitaFons

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`